.ui.primary.button, .ui.primary.buttons .button {
    background-color: #0b385b;
}
.ui.primary.button:hover, .ui.primary.buttons .button:hover {
    background-color: #3a3a3a;
}
.ui.primary.button:active, .ui.primary.buttons .button:active {
    background-color: #545454;
}
.ui.primary.button:focus, .ui.primary.buttons .button:focus {
    background-color: #3a3a3a;
}

*{
    border-radius:0!important;
    -webkit-box-shadow: none!important;
            box-shadow: none!important;
}
*:hover{
    border-radius:0!important;
    -webkit-box-shadow: none!important;
            box-shadow: none!important;
}

.extrasmall .ui.table thead th{
    padding: .92857143em .38571429em;
}
.extrasmall .ui.table td {
    padding: .78571429em .38571429em;
}


.blur{
  /* filter: blur(3px); */
}